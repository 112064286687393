<template>
  <b-card>
    <div></div>
    <div class="d-flex justify-content-between align-items-center w-100 mb-1">
      <h3>{{ title }}</h3>
      <div>
        <b-button variant="primary" @click="onClickedAdd()">
          <feather-icon icon="userIcon" /> إضافة</b-button>
      </div>
    </div>
    <div class="border pt-1 px-1 rounded-lg mb-1 shadow-sm">
      <b-row>
        <b-col cols="5">
          <form-input label="" v-model="filter" placeholder="ابحث هنا..." class="w-50" />
        </b-col>
      </b-row>
    </div>

    <data-table ref="estatesTable" :fields="fields" :filter.sync="filter" :ep="`${endpoint}`"
      :utils.sync="utils">
      <template #cell(created_at)="row">
        <div>
          {{ $moment(row.item.created_at).format("YYYY-MM-DD") }}
        </div>
      </template>
      <template #cell(time)="row">
        <div>
          {{ $moment(row.item.created_at, ['h:mm']).locale('ar').format('hh:mm A') }}
        </div>
      </template>
      <template #cell(actions)="row">
        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </template>

          <b-dropdown-item @click="onUpdateClicked(row.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">تعديل</span>
          </b-dropdown-item>

          <b-dropdown-item @click="onDelete(row.item)">
            <feather-icon icon="Trash2Icon" />
            <span class="align-middle ml-50">حذف</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </data-table>
    <form-modal ref="estateModalEventActivity" :formSchema="estatesFormSchema" title="إضافة " v-model="activModel"
      :loading="loadingBooking" @confirm="onModalConfirmed">
    </form-modal>
  </b-card>
</template>
<script>
import DataTable from "@/components/data-table/index";
import { mapActions, mapGetters } from "vuex";
import FormInput from "@/components/form-input/index";
import {
  BRow,
  BCol,
  BCard,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import FormModal from "@/components/FormModal.vue";

export default {
  components: {
    DataTable,
    FormInput,
    BRow,
    BCol,
    BCard,
    FormModal,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  props: ["id", "title"],
  data: () => {
    return {
      fields: [
        { key: "full_name", label: "اسم المستخدم", sortable: true },
        { key: "phone_number", label: "رقم التواصل" },
        { key: "number_contributor", label: "عدد الأشخاص" },
        { key: "created_at", label: " تاريخ الحجز" },
        { key: "time", label: "توقيت الحجز" },
        { key: "actions", label: "" },
      ],
      utils: {
        type: "",
        type_id: "",
      },
      filter: "",
      activModel: false,
      estatesFormSchema: {
        full_name: {
          component: "FormInput",
          key: "full_name",
          attrs: {
            label: "اسم فعالية",
            rules: "required",
          },
        },
        phone_number: {
          component: "FormMask",
          key: "phone_number",
          attrs: {
            options: "phone",
            rules: "required",
            label: "رقم تواصل",
            dir: "ltr",
          },
        },
        number_contributor: {
          component: "FormMask",
          key: "number_contributor",
          attrs: {
            options: "number",
            rules: "required",
            label: "عدد الحضور",
            dir: "ltr",
          },
        },
      },
      listRoute: [
        {
          CurrentRoute: "bookings-activites",
          name: "Activity",
          ep: "action-appointments",
          route: "bookings-activite",
        },
        {
          CurrentRoute: "bookings-events",
          name: "Event",
          ep: "action-appointments",
          route: "bookings-event",
        },
        {
          CurrentRoute: "bookings-interactive-museum",
          name: "interactive_museums",
          ep: "internal-activity-appointments",
          route: "booking-interactive-museum",
        },
        {
          CurrentRoute: "bookings-training-heritage",
          name: "heritage_trainings",
          ep: "internal-activity-appointments",
          route: "booking-training-heritage",
        },
        {
          CurrentRoute: "bookings-awareness-sessions",
          name: "awareness_sessions",
          ep: "internal-activity-appointments",
          route: "booking-awareness-sessions",
        },
      ],
      endpoint:'',
      slug:"",
    };
  },
  methods: {
    ...mapActions("manageCenter/bookings/activites", [
      "getAddBookingsEA",
      "deleteBookingsEA",
      "updateBookingsEA",
    ]),
    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف الإجراء", {
          title: "تأكيد حذف الإجراء",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
          this.deleteBookingsEA({
            ep: `${this.endpoint}/${item.id}`,
            data: {
              type: this.slug ,
              type_id: this.id,
            }
          }).then(() => {
            this.$refs.estatesTable.refreshTable();
          });
        });
    },
    onUpdateClicked(item) {
      var toUpdate = {
        id: item.id,
        full_name: item.full_name,
        phone_number: item.phone_number,
        number_contributor: item.number_contributor,
      };
      this.$refs.estateModalEventActivity.init(toUpdate);
      this.activModel = true;
    },
    onClickedAdd() {
      this.$refs.estateModalEventActivity.init({});
      this.activModel = true;
    },
    onModalConfirmed(form) {
      if (form.id) {
        this.updateBookingsEA({
          id: form.id,
          ep: `${this.endpoint}/${form.id}`,
          data: {
            ...form,
            type: this.slug ,
            type_id: this.id,
          },
        }).then(() => {
          this.activModel = false;
          this.$refs.estatesTable.refreshTable();
        });
      } else
        this.getAddBookingsEA({
          ep: this.endpoint,
          data: {
            ...form,
            number_contributor: form.number_contributor.replace(/\D/g, ""),
            type: this.slug ,
            type_id: this.id,
          },
        }).then(() => {
          this.activModel = false;
          this.$refs.estatesTable.refreshTable();
        });
    },
  },
  computed: {
    ...mapGetters("manageCenter/bookings/activites", [
      "bookingsEvent",
      "loading",
      "loadingBooking",
    ]),
  },
  created() {
    this.endpoint = this.listRoute.find((item) => item.route == this.$route.name).ep
    this.slug = this.listRoute.find((item) => item.route == this.$route.name).name

    this.utils.type_id = this.id;
    this.utils.type =this.slug;
  },
  beforeCreate(){

  },
  beforeMount(){

  },
  mounted(){

  }
};
</script>